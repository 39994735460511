<template>
    <div class="taplink-video">
        <video-embed :src="content.url"/>
    </div>
</template>

<script>
    export default {
        name: "BlockVideo",
        props: {
            content: {}
        }
    }
</script>

<style lang="scss">
    .taplink-video {
        position: relative;
        padding-top: 56%;
        width: 100%;
        border-radius: 5px;
        overflow: hidden;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            outline: none;
            border: none;
        }
    }
</style>